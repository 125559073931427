
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.min';

export default class Menu {

    /**
     * Set menu's height to body's padding top to make space for menu.
     */
    static setHeight() {
        const body = document.body;
        const menu = document.getElementById('menu-container');

        body.style.paddingTop = `${menu.offsetHeight}px`;
    }

    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;

        function init() {
            let menu = document.getElementById('menu-container');

            let currentScrollPos = window.pageYOffset;

            // if menu's height is bigger than scrolled area start scrolling
            if (menu) {
                if (menu.offsetHeight < currentScrollPos) {
                    menu.classList.add('menu-container--is-scrolled');
                } else {
                    menu.classList.remove('menu-container--is-scrolled');
                }
            }

            prevScrollPos = currentScrollPos;
        }

        // show menu on load
        init();
        window.onscroll = function () {
            // init menu on load
            init();
        };
    }

    /**
     * Set overflow hidden to body if menu is open (ban background-scrolling).
     */
    static listenOpen() {
        const checkbox = document.getElementById('nav-toggle'),
            navList = document.getElementsByClassName('nav__list')[0],
            menu = document.getElementsByClassName('menu-container')[0];

            checkbox.addEventListener('change', function(){

            if (checkbox.checked) {
                disableBodyScroll(navList);
                menu.classList.add('menu-container--opened')
               //document.body.style.overflow = 'hidden';;
            } else {
                enableBodyScroll(navList);
                menu.classList.remove('menu-container--opened')
               //document.body.style.overflow = '';
            }
        });
    }

    /**
     * Close menu when user click on one of its items. Only on mobile devices.
     */
    static closeCard() {
        const checkbox = document.getElementById('open-dropdown');

        document.addEventListener('click', function (event) {
            if (event.target.closest('.nav__cart-wrapper') !== null) return;
            if (checkbox.checked === true) checkbox.click();
        }, false);
    }
}
