
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

import Menu from './Menu';
import Slider from "./Slider";
import CounterUpSetting from "./CounterUpSetting";
import IsScrolledIntoView from "./IsScrolledIntoView";
import ScrollTo from "./ScrollTo";
import Form from "./Form";
import PhotoSwipePlugin from './PhotoSwipePlugin';

import AOS from 'aos';
import objectFitImages from 'object-fit-images';
import MicroModal from 'micromodal';

window.onload = function() {
    Menu.setScrolling();
    Menu.listenOpen();

    if ( document.querySelector('.js-counter') ) CounterUpSetting.init();

    let cards = document.querySelectorAll('.card');

    if ( cards.length > 0 && window.matchMedia('(min-width:768px)').matches ) {
        let maxHeight = 0;
        for (let i = 0; i < cards.length; i++) {
            //if ( cards[i].offsetHeight > maxHeight ) maxHeight = cards[i].offsetHeight;
        }
        for (let i = 0; i < cards.length; i++) {
            //cards[i].style.height = `${maxHeight}px`;
            //if ( i === 1) cards[i].style.height = `${maxHeight + 70}px`;
        }
    }

    const sectionTitles = document.querySelectorAll('.section-title');

    if (sectionTitles.length > 0) {
        for (let i = 0; i < sectionTitles.length; i++) {

            function doIt() {
                if ( IsScrolledIntoView.init(sectionTitles[i]) ) {
                    sectionTitles[i].classList.add('section-title--active');
                } else {
                    sectionTitles[i].classList.remove('section-title--active');
                }
            }

            doIt();

            window.addEventListener('scroll', function () {
                doIt();
            });
        }
    }

    Form.file();

    const accordions = document.querySelectorAll('.accordion');

    if (accordions.length > 0) {
        for (let i = 0; i < accordions.length; i++) {
            let title = accordions[i].querySelector('.accordion__title');

            if (title) {
                title.addEventListener('click', function () {
                    accordions[i].classList.toggle('open');
                });
            }
        }
    }

    Slider.init();
    ScrollTo.init();

    PhotoSwipePlugin.init();

    AOS.init({
        duration: 1000,
        easing: 'ease-in-sine',
        once: true
    });

    MicroModal.init({
        disableScroll: true
    });

    objectFitImages();
};