export default class IsScrolledIntoView {

    static init(elem) {
        if (elem) {
            var top = elem.offsetTop;
            var left = elem.offsetLeft;
            var width = elem.offsetWidth;
            var height = elem.offsetHeight;

            while (elem.offsetParent) {
                elem = elem.offsetParent;
                top += elem.offsetTop;
                left += elem.offsetLeft;
            }

            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }
    }
}
