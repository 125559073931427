import $ from "jquery";
window.$ = window.jQuery = $;
import 'slick-carousel';

export default class Slider {

    static init() {
        const projectSlider = $('#projectSlider');

        if (projectSlider.length > 0) {
            $(projectSlider).slick({
                centerMode: true,
                slidesToShow: 3,
                slideToScroll: 1,
                variableWidth: true,
                arrows: false,
                dots: true
            });
        }
    }
}

