
export default class ScrollTo {

    static init() {
        function getHash(str) {
            return str.split('#')[1];
        }

        if (window.location.hash) {
            console.log(window.location.hash);
            if ( document.querySelector(window.location.hash) ) doScrolling(window.location.hash, 500);
        }

        const elements = document.querySelectorAll('a[href*="#"]');

        function getElementY(query) {
            return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top - document.getElementById('menu-container').offsetHeight; // minus scrolled header
        }

        function doScrolling(element, duration) {
            var startingY = window.pageYOffset;
            var elementY = getElementY(element);
            // If element is close to page's bottom then window will scroll only to some position above the element.
            var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
            var diff = targetY - startingY;
            // Easing function: easeInOutCubic
            // From: https://gist.github.com/gre/1650294
            var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 };
            var start;

            if (!diff) return;

            // Bootstrap our animation - it will get called right before next frame shall be rendered.
            window.requestAnimationFrame(function step(timestamp) {
                if (!start) start = timestamp;
                // Elapsed miliseconds since start of scrolling.
                var time = timestamp - start;
                // Get percent of completion in range [0, 1].
                var percent = Math.min(time / duration, 1);
                // Apply the easing.
                // It can cause bad-looking slow frames in browser performance tool, so be careful.
                percent = easing(percent);

                window.scrollTo(0, startingY + diff * percent);

                // Proceed with animation as long as we wanted it to.
                if (time < duration) {
                    window.requestAnimationFrame(step)
                }
            })
        }



        if (elements.length > 0) {
            for (let i = 0; i < elements.length; ++i) {
                elements[i].addEventListener('click', function (e) {
                    let target = elements[i].getAttribute('href');
                    target = getHash(target);

                    if ( document.querySelector(`#${target}`) ) {
                        e.preventDefault();
                        doScrolling(`#${target}`, 500);
                    }
                });
            }
        }
    }
}