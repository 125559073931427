
import IsScrolledIntoView from "./IsScrolledIntoView";

export default class CounterUpSetting {

    static init() {
        const counters = document.querySelectorAll('.js-counter');

        if (counters.length > 0) {
            for (let i = 0; i < counters.length; i++) {
                window['doneOnce' + i] = false;

                function doIt() {
                    if ( IsScrolledIntoView.init(counters[i]) && !window['doneOnce' + i]) {
                        let toCount = counters[i].innerHTML;
                        let iterator = parseInt(counters[i].getAttribute('data-iterator'));
                        let timeout = 1;
                        if (iterator === 1) timeout = 100;
                        toCount = toCount.replace(/\s+/g, '');
                        toCount = parseInt(toCount);

                        let j = 0;
                        counters[i].innerHTML = j.toString();

                        let oneStep = function() {
                            counters[i].innerHTML = j.toLocaleString().replace(/,/g," ");
                            if ( j >= toCount ) clearInterval(startInterval);
                            j = j + iterator;
                            if ( j > toCount ) j = toCount;
                        };

                        let startInterval = setInterval(oneStep, timeout);

                        window['doneOnce' + i] = true;
                    }
                }

                doIt();

                window.addEventListener('scroll', function () {
                    doIt();
                });
            }
        }
    }
}
